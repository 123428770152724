.header {
   
    height: 120px;
}
.header-container {

}

.nav {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: #1D293F;
    justify-content: space-between;
}
.nav a{
     font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: #1D293F;
    justify-content: space-between;
}
.d-flex {
    justify-content:center;
    align-items: center;
}
.d-flex .signup_btn{
    width: 104px;
    height: 48px;
    background-color: #28B0A6;
    color: #FFFFFF;
    border-radius: 28px;
    justify-content:center;
    align-items: center;
    display: flex;
    margin-left: 10px;
    
}
.logo{
    height: 70px !important;
}